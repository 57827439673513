<template>
  <Dialog
    v-model:visible="showUpdatingDialog"
    :showHeader="false"
    :closeOnEscape="false"
    :closable="false"
    :draggable="false"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <Loading :title="'Updating ' + entityName" :full="false" class="mt-5" />
  </Dialog>
  <Dialog v-model:visible="showUpdatePhoneDialog" modal :draggable="false" header="Update Phone number">
		<div class="text-center">
			<p class="m-0 p-0 w-full">You are about to update the users phone number to <span class="font-bold">"+{{user.phoneCountryCode}} {{user.phone}}" </span>. </p>
      <p>This will have an effect on the users login.</p>
			<p class="m-0 p-0 w-full"></p>
      <div class="flex justify-content-evenly mt-4">
        <Button  
          class="w-4 danger p-button-danger p-2" @click="() =>showUpdatePhoneDialog = false" :label="'Cancel'" />
        <Button 
          class="w-4 danger p-2" @click="updatePhone" :label="'Accept update'" />
      </div>
		</div>
	</Dialog>
    <Dialog v-model:visible="showUpdateEmailDialog" modal :draggable="false" header="Update Email">
		<div class="text-center">
			<p class="m-0 p-0 w-full">You are about to update the users email to <span class="font-bold">"{{user.email}}" </span>. </p>
      <p>This can have an effect on the users login.</p>
			<p class="m-0 p-0 w-full"></p>
      <div class="flex justify-content-evenly mt-4">
        <Button  
          class="w-4 danger p-button-danger p-2" @click="() => showUpdateEmailDialog = false" :label="'Cancel'" />
        <Button 
          class="w-4 danger p-2" @click="updateEmail" :label="'Accept'" />
      </div>
		</div>
	</Dialog>
  <div class="surface-section">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ pageDescription }}.
        </div>
      </div>
      <div class="flex gap-2 justify-content-end align-items-center">
        <Button
          v-if="hasPermission('np.backoffice.users.editdelete')"
          :disabled="!userUpdated"
          @click="updateEntity"
          :label="'Update ' + entityName"
          icon="pi pi-cloud-upload"
        />
      </div>
    </div>

    <Loading v-if="loading" :title="'Loading User'" :full="false" />
    <div class="text-center" v-if="!loading && !user">
      <h3>User not found</h3>
    </div>
    
    <div v-if="user" class="card">
      <Card class="mb-4">
        <template #content>
          <div class="flex">
            <div class="flex flex-1">
              <div>
                <Avatar
                  :image="user.profilePhotoUrl"
                  class="mr-2"
                  size="xlarge"
                  shape="circle"
                ></Avatar>
              </div>
              <div class="ml-3 align-items-center">
                <div class="font-semibold text-xl">
                  {{ user.firstName }} {{ user.lastName }}
                </div>
                <div class="mt-2">{{ user.email }}</div>
                <div class="flex align-items-center mt-2" v-if="coinsAccount">
                  <span class=""
                    >Coins: <strong>{{ coinsAccount.balance }}</strong></span
                  >
                  <img
                    src="@/assets/nightcoins.svg"
                    style="height: 30px; width: auto"
                    alt="Coins"
                    class="icon ml-2"
                  />
                </div>
              </div>
            </div>
            <div class="flex align-items-center">
              <Button v-if="user.subscriptionProvider == 'BillWerk'"
                @click="goToBillWerk"
                label="BillWerk"
                icon="pi pi-link"
                class="p-button-secondary"
              />
              <Button v-if="user.subscriptionProvider == 'Stripe'"
                @click="goToStripe"
                label="Stripe"
                icon="pi pi-link"
                class="p-button-secondary"
              />
              <Button
                @click="goToAnalytics"
                label="Analytics"
                icon="pi pi-link"
                class="ml-2 p-button-secondary"
              />
              <Button
                @click="goToMixPanel"
                label="Mixpanel"
                icon="pi pi-link"
                class="ml-2 p-button-secondary"
              />
            </div>
          </div>
        </template>
      </Card>

      <Form
        :fields="fields"
        :context="user"
        @onChange="entityUpdated"
        :hasAction="false"
      />

      <Card class="px-2 pt-2 h-9rem flex-grow-1 mb-4" v-if="phoneUsage" >
        <template #header>
          <div class="w-full m-0">
            <h4 class="m-0">Anti fraud statistics for {{fullPhoneNumber}}</h4>
          </div>
        </template>
         <template #content>
          <div class="w-full grid">
            <div class="col">
              <p class="m-0 font-medium text-sm">Number of users created</p>
              <p class="m-0 mt-2 bg-gray-100 px-2 text-center">{{numberOfCreatedUsersWithPhoneNumber}}</p>
            </div>
            <div class="col">
              <p class="m-0 font-medium text-sm">Number of subscription signups</p>
              <p class="m-0 mt-2 bg-gray-100 px-2 text-center">{{numberOfMembershipSignupsWithPhoneNumber}}</p>
            </div>
            <div class="col">
              <p class="m-0 font-medium text-sm">Number of subscription trials awarded</p>
              <p class="m-0 mt-2 bg-gray-100 px-2 text-center">{{numberOfTrialEndedWithPhoneNumber}}</p>
            </div>
          </div>
         </template>
      </Card>

      <Card class="p-2 flex-grow-1 mb-5">
        <template #header>
          <div class="flex">
            <h4 class="m-0">OnlinePOS card</h4>
          </div>
        </template>
        <template #content>
          <div class="formgrid grid">
            <div class="field col">
              <label class="font-medium text-sm">Number</label>
              <InputText
                type="text"
                v-model="onlinePosCard.cardNumber"
                readonly
              />
            </div>
            <div class="field col">
              <label class="font-medium text-sm">Name</label>
              <InputText
                type="text"
                v-model="onlinePosCard.cardName"
                readonly
              />
            </div>
            <div class="field col">
              <label class="font-medium text-sm">Group</label>
              <InputText
                type="text"
                v-model="onlinePosCard.cardGroup"
                readonly
              />
            </div>
          </div>

          <div class="flex flex-wrap justify-content-left">
            <Button
              v-if="onlinePosCard.cardNumber"
              @click="checkCard"
              label="Check card"
              icon="pi pi-check"
              class="p-button-primary"
            />
            &nbsp;
            <Button
              v-if="onlinePosCard.cardNumber"
              @click="listCampaigns"
              label="List campaign values"
              icon="pi pi-list"
              class="p-button-primary"
            />&nbsp;
            <Button
              v-if="showCreate"
              @click="createCard"
              label="Create new card"
              icon="pi pi-file"
              class="p-button-danger"
            />
          </div>
        </template>
      </Card>

      <Card v-if="showCampaigns" class="p-2 flex-grow-1 mb-5">
        <template #header>
          <div class="flex">
            <h4 class="m-0">OnlinePoS Campaigns</h4>
          </div>
        </template>
        <template #content>
          <DataTable :value="campaigns">
            <Column field="name" header="Campaign"></Column>
            <Column field="campaignId" header="Campaign Id"></Column>
            <Column field="points" header="Campaign Points"></Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";

export default {
  inject: ["hasPermission"],
  components: {},
  setup() {
    const storeActionUpdateEntity = "users/updateUserInformation";
    const storeActionUpdatePhone = "users/updateUserPhone";
    const storeActionUpdateEmail = "users/updateUserEmail";
    const storeActionCheckCard = "users/checkCard";
    const storeGetterGetCard = "users/onlinePosCard";
    const storeActionCreateCard = "users/createOnlinePosCard";
    const storeActionGetCampaigns = "users/getCampaigns";
    const storeGetterGetCampaigns = "users/campaignInfo";
    const entityName = "User";
    const pageTitle = "General";
    const pageDescription = "General User information.";

    const loading = ref(true);
    const updating = ref(false);

    const store = useStore();
    const toast = useToast();
    const route = useRoute();

    const user = ref();
    const coinsAccount = ref();
    const unUpdateUser = ref();
    const userUpdated = computed(()=>{
          return user.value?.firstName != unUpdateUser.value?.firstName ||
                          user.value?.lastName != unUpdateUser.value?.lastName ||
                          user.value?.gender != unUpdateUser.value?.gender ||
                          user.value?.email != unUpdateUser.value?.email ||
                          user.value?.phone?.replaceAll(' ', '') != unUpdateUser.value?.phone?.replaceAll(' ', '')
      
    });
    const userId = route.params.userId;

    const showUpdatingDialog = ref(false);
    const showUpdatePhoneDialog = ref(false)
    const showUpdateEmailDialog = ref(false)
    const showCampaigns = ref(false);
    const showCreate = ref(false);


    const fullPhoneNumber = ref()
    const phoneUsage = ref()
    const numberOfCreatedUsersWithPhoneNumber = computed(() => phoneUsage.value?.userCreations?.length ?? 0)
    const numberOfMembershipSignupsWithPhoneNumber = computed(() => phoneUsage.value?.userMembershipSignups?.length ?? 0)
    const numberOfTrialEndedWithPhoneNumber = computed(() => phoneUsage.value?.userMembershipTrials?.length ?? 0)

    const campaigns = ref([]);

    const onlinePosCard = computed(() => {
      if (user.value.onlinePosCard != null) {
        const decoded = user.value.onlinePosCard;

        return {
          cardNumber: decoded.cardNumber,
          cardName: decoded.cardName,
          cardGroup: decoded.cardGroup,
        };
      }
      return {};
    });

    const fields = ref([
      {
        id: "id",
        type: "text-input",
        title: "User ID",
        config: {
          readonly: "true",
        },
      },
      {
        id: "created",
        type: "text-datetime",
        title: "Joined",
      },
      {
        id: "lastAppOpen",
        type: "text-datetime",
        title: "Last App Open",
      },
      {
        id: "firstName",
        type: "text-input",
        title: "First Name",
      },
      {
        id: "lastName",
        type: "text-input",
        title: "Last Name",
      },
      {
        id: "email",
        type: "text-input",
        title: "Email",
      },
         {
          id: 'phone',
          type: 'phone-input',
          title: 'Phone'
        },
      {
        id: "gender",
        type: "dropdown",
        title: "Gender",
        config: {
          options: ["NoGender", "Male", "Female"],
        },
      },
      {
        id: "accountId",
        path: "accountId.id",
        type: "country",
        title: "Country",
      },
      {
        id: "shareCode",
        type: "text-input",
        title: "Share Code",
        config: {
          readonly: "true",
        },
      },
      {
        id: "crmGuestId",
        path: "crmGuestId.id",
        type: "text-input",
        title: "CRM Guest ID",
        config: {
          readonly: "true",
        },
      },
    ]);

    function goToStripe() {
      window.open(
        "https://dashboard.stripe.com/customers/" + user.value.stripeCustomerId,
        "_blank"
      );
    }

    function goToBillWerk() {
      window.open('https://admin.billwerk.plus/#/rp/customers/customers/customer/' + user.value.billWerkCustomerHandle, '_blank');
    }

    function goToAnalytics() {
      window.open(
        "https://console.firebase.google.com/u/0/project/rekom-nightpay-bdf26/overview",
        "_blank"
      );
    }

    function goToMixPanel() {
      window.open(
        "https://eu.mixpanel.com/project/2893120/view/3422448/app/profile#distinct_id=" +
          user.value.id
      );
    }

    async function createCard() {
      loading.value = true;
      try {
        await store.dispatch(storeActionCreateCard, { userId });
        const onlinePosCard = store.getters[storeGetterGetCard];

        if (onlinePosCard.cardDeleted) {
          toast.add({
            severity: "warn",
            summary: "Card deleted",
            detail:
              user.value.onlinePosCard.cardNumber + " is deleted in OnlinePOS!",
            life: 3000,
          });
          showCreate.value = true;
        } else {
          toast.add({
            severity: "success",
            summary: "New OnlinePOS card created",
            detail:
              user.value.onlinePosCard.cardNumber +
              " was created in OnlinePOS!",
            life: 3000,
          });
          user.value.onlinePosCard = onlinePosCard;
          showCreate.value = false;
        }
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error checking card against OnlinePOS",
          detail: err,
          life: 3000,
        });
      }
      loading.value = false;
    }

    async function checkCard() {
      loading.value = true;
      try {
        await store.dispatch(storeActionCheckCard, { userId });
        const onlinePosCard = store.getters[storeGetterGetCard];

        if (onlinePosCard.cardDeleted) {
          toast.add({
            severity: "warn",
            summary: "Card deleted",
            detail:
              user.value.onlinePosCard.cardNumber + " is deleted in OnlinePOS!",
            life: 3000,
          });
          showCreate.value = true;
        } else {
          toast.add({
            severity: "success",
            summary: "Card found",
            detail:
              user.value.onlinePosCard.cardNumber + " was found in OnlinePOS!",
            life: 3000,
          });
          user.value.onlinePosCard = onlinePosCard;
          showCreate.value = false;
        }
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error checking card against OnlinePOS",
          detail: err,
          life: 3000,
        });
      }
      loading.value = false;
    }

    async function listCampaigns() {
      loading.value = true;
      try {
        await store.dispatch(storeActionGetCampaigns, { userId });
        const campaignInfo = store.getters[storeGetterGetCampaigns];
        console.log("campaignInfo", campaignInfo);
        campaigns.value = campaignInfo.campaigns;

        if (campaignInfo.cardDeleted) {
          toast.add({
            severity: "warn",
            summary: "Card deleted",
            detail:
              user.value.onlinePosCard.cardNumber + " is deleted in OnlinePOS!",
            life: 3000,
          });
          showCreate.value = true;
        } else {
          toast.add({
            severity: "success",
            summary: "Campaign info loaded",
            detail:
              campaigns.value.length + " campaigns was found in OnlinePOS!",
            life: 3000,
          });
          showCampaigns.value = true;
          showCreate.value = false;
        }
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error getting campaigns from OnlinePOS",
          detail: err,
          life: 3000,
        });
      }
      loading.value = false;
    }

    function entityUpdated(formContext) {

      user.value = {...formContext};

      if(formContext?.phoneCountryCode == null )
        user.value.phoneCountryCode = "45"
    }




    async function updateGeneralInformation(){
      showUpdatingDialog.value = true

      try {
        var userInfo = {
          firstName: user.value.firstName,
          lastName: user.value.lastName,
          gender: user.value.gender,
        };

        await store.dispatch(storeActionUpdateEntity, {
          userId: userId,
          user: userInfo,
        });
        toast.add({
          severity: "success",
          summary: "Success",
          detail: entityName + " updated",
          life: 3000,
        });

      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not update " + entityName + ": " + err,
          life: 3000,
        });
      }
      showUpdatingDialog.value = false

      unUpdateUser.value = user.value;

    }

    function changesPending(){
      return user.value.firstName != unUpdateUser.value.firstName ||
                          user.value.lastName != unUpdateUser.value.lastName ||
                          user.value.gender != unUpdateUser.value.gender ||
                          user.value.email != unUpdateUser.value.email ||
                          user.value.phone?.replaceAll(' ', '') != unUpdateUser.value.phone?.replaceAll(' ', '')
    }

    async function updatePhone(){
        showUpdatingDialog.value = true
        showUpdatePhoneDialog.value = false
        
        var phoneInfo = {
          phone: user.value.phone?.replaceAll(' ', ''),
          phoneCountryCode: user.value.phoneCountryCode
          };

       await store.dispatch(storeActionUpdatePhone, {
          userId: userId,
          phoneInfo: phoneInfo,
        })
        .then(()=>{
            toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: entityName + " updated",
                    life: 3000,
                  });

        })
        .catch((err)=>{
            toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Could not update Phone number: " + err,
                  life: 3000,
                });
        })
        showUpdatingDialog.value = false

        fullPhoneNumber.value = user.value?.phoneCountryCode + user.value?.phone
        unUpdateUser.value.phone = user.value.phone
        updateEntity();
    }

   async function updateEmail(){
        showUpdatingDialog.value = true
        showUpdateEmailDialog.value = false

                
        var emailInfo = {
          email: user.value.email
          };

        await store.dispatch(storeActionUpdateEmail, {
          userId: userId,
          emailInfo: emailInfo,
        })
        .then(()=>{
            toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: entityName + " updated",
                    life: 3000,
                  });

        })
        .catch((err)=>{
            toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Could not update email: " + err,
                  life: 3000,
                });
          user.value.email = unUpdateUser.value.email

        })

        showUpdatingDialog.value = false

        unUpdateUser.value.email = user.value.email
        updateEntity();
    }


    async function updateEntity() {

      if(!changesPending())
        return;
      
      if(user.value.phone?.replaceAll(" ", "") != unUpdateUser.value.phone?.replaceAll(" ", "")){
        showUpdatePhoneDialog.value = true
        return;
      }

      if(user.value.email != unUpdateUser.value.email){
        showUpdateEmailDialog.value = true
        return;
      }

       updateGeneralInformation()
  
    }

    async function getPhoneUsageAsync(){
    
      var phoneCountryCode = user.value.phoneCountryCode
      var phoneNumber = user.value.phone
      
      if(phoneCountryCode == null || phoneNumber == null) 
          return;

      await store.dispatch("phoneUsage/getPhoneUsage", { phoneCountryCode, phoneNumber })
        .catch((err)=>{
          console.log(err)
        });

      phoneUsage.value = store.getters["phoneUsage/phoneUsage"]( phoneCountryCode, phoneNumber )

    }

    async function loadRequiredData() {
      loading.value = true;
      try {
        await store.dispatch("users/ensureUser", { userId: userId });
        // coinsAccount.value = await store.dispatch("users/getCoinsAccount", { userId: userId });
        const loadedUser = store.getters["users/user"](userId);
        // coinsAccount.value = store.getters["users/coinsAccount"];
        console.log("coinsAccount", coinsAccount.value);
        user.value = JSON.parse(JSON.stringify(loadedUser));
        unUpdateUser.value = JSON.parse(JSON.stringify(loadedUser));

        fullPhoneNumber.value = user.value?.phoneCountryCode + user.value?.phone

        await getPhoneUsageAsync();

        showCreate.value = !user.value.onlinePosCard;
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Could not find user",
          detail: err,
          life: 3000,
        });
        loading.value = false;
      }

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      pageTitle,
      pageDescription,
      loading,
      updating,

      phoneUsage,
      numberOfTrialEndedWithPhoneNumber,
      numberOfCreatedUsersWithPhoneNumber,
      numberOfMembershipSignupsWithPhoneNumber,

      entityUpdated,
      updateEntity,
      updateEmail,
      updatePhone,
      userUpdated,

      user,
      onlinePosCard,
      coinsAccount,
      fullPhoneNumber,

      fields,
      campaigns,

      entityName,

      showUpdatingDialog,
      showUpdatePhoneDialog,
      showUpdateEmailDialog,
      showCampaigns,
      showCreate,

      checkCard,
      listCampaigns,
      createCard,

      goToStripe,
      goToBillWerk,
      goToAnalytics,
      goToMixPanel,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vs-avatar img {
  height: auto;
  display: block;
}

.vs-avatar,
.vs-avatar img {
  border-radius: inherit;
  width: 100%;
  transition: all 0.25s ease;
}

.vs-avatar,
.vs-avatar img {
  border-radius: inherit;
  width: 100%;
  transition: all 0.25s ease;
}

.vs-avatar {
  background: #f4f7f8;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2c3e50;
  height: 100%;
  box-shadow: inset 0 0 0 0 rgb(0 0 0 / 5%);
}

.vs-avatar-content {
  border-radius: 50%;
  z-index: 1;
  width: 44px;
  height: 44px;
  position: relative;
  transition: all 0.25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
