import apiClient from '@/services/axios'

export async function get(phoneCountryCode, phoneNumber) {
  var response = await apiClient.get(`/phoneUsage?phoneCountryCode=${phoneCountryCode}&phoneNumber=${phoneNumber}`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get phone usage.')
}
