import * as apiGetPhoneUsage from '@/api/phoneUsage/getPhoneUsage'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        detailedPhoneUsage: {}
    }),
    mutations: {

        gotPhoneUsage(state, {phoneUsage, phoneCountryCode, phoneNumber}) {
            var id = phoneCountryCode + phoneNumber;
            console.log("HAD", phoneUsage)
            state.detailedPhoneUsage[id] = phoneUsage
            console.log(state.detailedPhoneUsage)
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
       

        async getPhoneUsage({ commit }, { phoneCountryCode, phoneNumber }) {
            commit('isLoading', true)

            console.log('Getting PhoneUsage:', phoneNumber);
        
            const result = await apiGetPhoneUsage.get(phoneCountryCode, phoneNumber)
            if (result) {
                commit('gotPhoneUsage', {phoneUsage: result, phoneCountryCode, phoneNumber})
            }
        
            commit('isLoading', false)
        },

        async ensurePhoneUsage({ commit, dispatch, getters }, { phoneCountryCode, phoneNumber }) {
            commit('isLoading', true)

            if (!getters.phoneUsage(phoneCountryCode, phoneNumber )) {
                console.log('Phone usage not cached. Getting from API.')
                await dispatch('getPhoneUsage', {phoneCountryCode, phoneNumber})
            }

            commit('isLoading', false)
        }
    },
    getters: {
        phoneUsage: (state) => (phoneCountryCode, phoneNumber) => {
            var id = phoneCountryCode + phoneNumber;

            return  state.detailedPhoneUsage[id]
        }
    }
};