import * as apiGetUser from '@/api/users/getUser';
import * as apiGetUserCoinsAccount from '@/api/users/getUserCoinsAccount';
import * as apiGetUsers from '@/api/users/getUsers';
import * as apiGetUsersPaging from '@/api/users/getUsersPaging';
import * as apiDeleteUser from '@/api/users/deleteUser';
import * as apiDeleteUserMembership from '@/api/users/deleteUserMembership';
import * as apiPauseUserMembership from '@/api/users/pauseUserMembership';
import * as apiResumeUserMembership from '@/api/users/resumeUserMembership';
import * as apiUpdateUserPermissions from '@/api/users/updateUserPermissions';
import * as apiUpdateUserMembership from '@/api/users/updateUserMembership';
import * as apiUpdateUserCountry from '@/api/users/updateUserCountry';
import * as apiUpdateUserMembershipFreeDays from '@/api/users/updateUserMembershipTrial';
import * as apiGetUserInformation from '@/api/users/updateUserInformation';
import * as apiGetUserSubscriptionTransactions from '@/api/transactions/getSubscriptionTransactions';
import * as apiGetUserOnlinePosCard from '@/api/users/checkCard';
import * as apiCreateUserOnlinePosCard from '@/api/users/createOnlinePosCard';
import * as apiGetUserCampaigns from '@/api/users/getCampaigns';
import * as apiGetUsersFromFile from '@/api/users/getUsersFromFile';
import * as apiGetUsersByIds from '@/api/users/getUsersByIds';
import * as apiGetUserEventLog from '@/api/users/getUserEventLog';
import * as apiUpdateUserPhone from '@/api/users/updateUserPhone';
import * as apiUpdateUserEmail from '@/api/users/updateUserEmail';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		userListItems: [],
		subscriptionTransactions: [],
		pagingObject: {},
		detailedUsers: {},
		onlinePosCard: {},
		campaignInfo: {},
		eventLogWrapper: null,
		coinsAccount: null,
	}),
	mutations: {
		gotUserListItems(state, users) {
			state.userListItems = users;
		},

		gotUsersPaging(state, pagingObject) {
			state.usersPagingObject = pagingObject;
		},

		gotUserCoinsAccount(state, coinsAccount) {
			state.coinsAccount = coinsAccount;
		},

		gotUser(state, user) {
			state.detailedUsers[user.id] = user;
		},

		gotUserEventLog(state, eventLogWrapper) {
			state.eventLogWrapper = eventLogWrapper;
		},

		gotUserSubscriptionTransactions(state, transactions) {
			state.subscriptionTransactions = transactions;
		},

		gotUserOnlinePosCard(state, onlinePosCard) {
			state.onlinePosCard = onlinePosCard;
		},

		gotCampaigns(state, campaignInfo) {
			state.campaignInfo = campaignInfo;
		},

		gotUsersFromFile(state, users) {
			state.userListItems = users;
		},

		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async getUserListItems({ commit }) {
			commit('isLoading', true);

			console.log('Getting User List Items');

			const result = await apiGetUsers.get();

			if (result) {
				commit('gotUserListItems', result);
			}

			commit('isLoading', false);
		},

		async getUsersPaging({ commit }, payload) {
			commit('isLoading', true);

			console.log('Getting User List Paging');

			const result = await apiGetUsersPaging.get(payload);

			if (result) {
				console.log('gotUsersPaging', result);
				commit('gotUsersPaging', result);
			}

			commit('isLoading', false);
		},

		async getUser({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Getting User:', userId);
			
			const result = await apiGetUser.get(userId);

			if (result) {
				console.log('Getting User:', result);
				commit('gotUser', result);
			}

			commit('isLoading', false);
		},

		async getCoinsAccount({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Getting User Coins Account:', userId);

			const result = await apiGetUserCoinsAccount.get(userId);

			if (result) {
				commit('gotUserCoinsAccount', result);
			}

			commit('isLoading', false);
			return result;
		},

		async getUserEventLog({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Getting User event log:', userId);

			const result = await apiGetUserEventLog.get(userId);

			if (result) {
				commit('gotUserEventLog', result);
			}

			commit('isLoading', false);
		},

		async getUserSubscriptionTransactions({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Getting User subscription-transactions:', userId);

			const result = await apiGetUserSubscriptionTransactions.get(userId);

			if (result) {
				commit('gotUserSubscriptionTransactions', result);
			}

			commit('isLoading', false);
		},

		async checkCard({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Checking user onlinepos card:', userId);

			const result = await apiGetUserOnlinePosCard.get(userId);

			if (result) {
				commit('gotUserOnlinePosCard', result);
			}

			commit('isLoading', false);
		},

		async createOnlinePosCard({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Creating new user onlinepos card:', userId);

			const result = await apiCreateUserOnlinePosCard.put(userId);

			if (result) {
				commit('gotUserOnlinePosCard', result);
			}

			commit('isLoading', false);
		},

		async getCampaigns({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Getting user campaigns:', userId);

			const result = await apiGetUserCampaigns.get(userId);

			if (result) {
				commit('gotCampaigns', result);
			}

			commit('isLoading', false);
		},

		async deleteUser({ commit }, { userId }) {
			commit('isLoading', true);

			console.log('Deleting User:', userId);

			await apiDeleteUser.del(userId);

			commit('isLoading', false);
		},

		async deleteUserMembership({ commit }, { userId, cancelAtPeriodEnd }) {
			commit('isLoading', true);

			console.log('Canceling UserMembership:', userId);

			await apiDeleteUserMembership.del(userId, cancelAtPeriodEnd);

			commit('isLoading', false);
		},

		async pauseUserMembership({ commit }, { userId, nMonths, prolong }) {
			commit('isLoading', true);
			console.log('Pausing UserMembership:', userId, nMonths);

			await apiPauseUserMembership.put(userId, nMonths, prolong);

			commit('isLoading', false);
		},

		async resumeUserMembership({ commit }, userId) {
			commit('isLoading', true);

			console.log('Resuming UserMembership:', userId);

			await apiResumeUserMembership.put(userId);

			commit('isLoading', false);
		},

		async updateUserMembership({ commit }, { userId, subscriptionTier }) {
			commit('isLoading', true);

			await apiUpdateUserMembership.put(userId, { subscriptionTier: subscriptionTier });

			commit('isLoading', false);
		},

		async updateUserMembershipFreeDays({ commit }, { userId, trialDays }) {
			commit('isLoading', true);
			await apiUpdateUserMembershipFreeDays.put(userId, { trialDays: trialDays });

			commit('isLoading', false);
		},

		async updateUserPermissions({ commit }, { userId, permissions }) {
			commit('isLoading', true);

			await apiUpdateUserPermissions.put(userId, permissions);

			commit('isLoading', false);
		},

		async updateUserCountry({ commit }, { userId, countryCode }) {
			commit('isLoading', true);

			await apiUpdateUserCountry.put(userId, { countryCode: countryCode });

			commit('isLoading', false);
		},

		async updateUserInformation({ commit }, { userId, user }) {
			commit('isLoading', true);

			await apiGetUserInformation.put(userId, { ...user });

			commit('isLoading', false);
		},

		async updateUserPhone({ commit }, { userId, phoneInfo }) {
			commit('isLoading', true);

			await apiUpdateUserPhone.put(userId, { ...phoneInfo });

			commit('isLoading', false);
		},
		
		async updateUserEmail({ commit }, { userId, emailInfo }) {
			commit('isLoading', true);

			await apiUpdateUserEmail.put(userId, { ...emailInfo });

			commit('isLoading', false);
		},

		/*
        async saveUser({ commit }, { user }) {
            commit('isLoading', true)

            console.log(user)

            const payload = {
                title: workspace.title
            };

            await apiUpdateWorkspaceTemplate.put(workspace.id, payload)
            
            commit('isLoading', false)
        },*/

		async ensureCoinsAccount({ commit, dispatch, getters }, { userId }) {
			commit('isLoading', true);

			if (!getters.coinsAccount) {
				console.log('Coins account not cached. Getting from API.');
				await dispatch('getCoinsAccount', { userId: userId });
			}

			commit('isLoading', false);
		},

		async ensureUserListItems({ commit, dispatch, getters }) {
			commit('isLoading', true);

			if (getters.userListItems.length === 0) {
				await dispatch('getUserListItems');
			}

			commit('isLoading', false);
		},

		async ensureUser({ commit, dispatch, getters }, { userId }) {
			commit('isLoading', true);

			if (!getters.user(userId)) {
				console.log('User not cached. Getting from API.');
				await dispatch('getUser', { userId: userId });
			}

			commit('isLoading', false);
		},

		async getUsersFromFile({ commit }, file) {
			commit('isLoading', true);

			const result = await apiGetUsersFromFile.post(file);

			if (result) {
				commit('gotUsersFromFile', result);
			}

			commit('isLoading', false);
			return result;
		},

		async getUsersFromIds({ commit }, userIds) {
			commit('isLoading', true);

			const result = await apiGetUsersByIds.get(userIds);

			if (result) {
				commit('gotUserListItems', result);
			}

			commit('isLoading', false);
			return result;
		},
	},
	getters: {
		userListItems: (state) => state.userListItems,
		usersPagingObject: (state) => state.usersPagingObject,
		user: (state) => (userId) => {
			return state.detailedUsers[userId];
		},
		eventLogWrapper: (state) => state.eventLogWrapper,
		userSubscriptionTransactions: (state) => state.subscriptionTransactions,
		onlinePosCard: (state) => state.onlinePosCard,
		campaignInfo: (state) => state.campaignInfo,
		coinsAccount: (state) => state.coinsAccount,
	},
};
