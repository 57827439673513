<template>
  <Dialog
    v-model:visible="showUpdatingDialog"
    :showHeader="false"
    :closeOnEscape="false"
    :closable="false"
    :draggable="false"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <Loading :title="'Updating ' + entityName" :full="false" class="mt-5" />
  </Dialog>
  <div class="surface-section">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ pageDescription }}.
        </div>
      </div>
      <div class="flex justify-content-end align-items-center">
        <Button
          v-if="hasPermission('np.backoffice.users.editdelete')"
          @click="updateEntity"
          :label="'Save ' + entityName"
          icon="pi pi-cloud-upload"
        />
      </div>
    </div>

    <Loading v-if="loading" :title="'Loading Permissions'" :full="false" />
    <div v-else class="card">
      <Form
        :fields="fields"
        :context="user"
        @onChange="entityUpdated"
        :hasAction="false"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";

export default {
  inject: ["hasPermission"],
  components: {},
  setup() {
    const storeActionUpdateEntity = "users/updateUserPermissions";
    const entityName = "Permissions";
    const pageTitle = "Permissions";
    const pageDescription = "User Permission information.";

    const loading = ref(true);
    const updating = ref(false);

    const store = useStore();
    const toast = useToast();
    const route = useRoute();

    const user = ref();
    const userId = route.params.userId;

    const showUpdatingDialog = ref(false);

    const fields = ref([
      {
        id: "showRegisterBeacon",
        type: "switch",
        title: "Register Beacon",
        horizontal: true,
      },
      {
        id: "isStaff",
        type: "switch",
        title: "User is staff",
        horizontal: true,
      },
      // {
      //   id: 'showSharePageStaffVersion',
      //   type: 'switch',
      //   title: 'Share Page (staff version)',
      //   horizontal: true
      // },
      // {
      //   id: 'showSendADrinkStaffPage',
      //   type: 'switch',
      //   title: 'Send a Drink (staff version)',
      //   horizontal: true
      // },
      // {
      //   id: 'showRekomBenefits',
      //   type: 'switch',
      //   title: 'Show REKOM Benefits',
      //   horizontal: true
      // },
      {
        id: "showAssignPerksPage",
        type: "switch",
        title: "Assign Perks",
        horizontal: true,
      },
      {
        id: "hasDevModeAccess",
        type: "switch",
        title: "User has dev mode access",
        horizontal: true,
      },
      {
        id: "hasXMonthsAccess",
        type: "switch",
        title: "User has X months access",
        horizontal: true,
      },
      {
        id: "hasCoinsAccess",
        type: "switch",
        title: "User has NightCoins access",
        horizontal: true,
      },
      {
        id: "whitelistedForTestPayments",
        type: "switch",
        title: "User is allowed to make test payments",
        horizontal: true,
      },
    ]);

    function entityUpdated(formContext) {
      user.value = formContext;
    }

    async function updateEntity() {
      showUpdatingDialog.value = true;

      try {
        await store.dispatch(storeActionUpdateEntity, {
          userId: userId,
          permissions: user.value,
        });

        toast.add({
          severity: "success",
          summary: "Success",
          detail: entityName + " updated",
          life: 3000,
        });

        loading.value = true;
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not update " + entityName + ": " + err,
          life: 3000,
        });
      }

      loading.value = false;
      showUpdatingDialog.value = false;
    }

    async function loadRequiredData() {
      loading.value = true;

      await store.dispatch("users/getUser", { userId: userId });
      const loadedUser = store.getters["users/user"](userId);

      user.value = JSON.parse(JSON.stringify(loadedUser));

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      pageTitle,
      pageDescription,
      loading,
      updating,

      entityUpdated,
      updateEntity,

      user,

      fields,

      entityName,

      showUpdatingDialog,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
